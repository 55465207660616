/* 
* This function waits for a resize event and fires only when it
* detects a 'true' resize, helping it conserve resources
*/
var optimizedResize = (function() {

    var callbacks = [],
        running = false;

    // fired on resize event
    function resize() {

        if (!running) {
            running = true;

            if (window.requestAnimationFrame) {
                window.requestAnimationFrame(runCallbacks);
            } else {
                setTimeout(runCallbacks, 66);
            }
        }

    }

    // run the actual callbacks
    function runCallbacks() {

        callbacks.forEach(function(callback) {
            callback();
        });

        running = false;
    }

    // adds callback to loop
    function addCallback(callback) {

        if (callback) {
            callbacks.push(callback);
        }

    }

    return {
        // initalize resize event listener
        init: function(callback) {
            window.addEventListener('resize', resize);
            addCallback(callback);
        },

        // public method to add additional callback
        add: function(callback) {
            addCallback(callback);
        }
    }
}());


//mega menus
$(document).on('click', '.yamm .dropdown-menu', function (e) {
  e.stopPropagation()
});

//homepage tabs to have slide down effect
$('.nav-tabs.secondary a').click(function (e) {
  e.preventDefault()
  $("tab-pane").slideDown(500);
})

//carousel 
$('.carousel-inner').carousel({
  interval: 8000
})

//off canvas menu
$(function () {
  $('.toggle-nav').click(function () {
    // Calling a function in case you want to expand upon this.
    toggleNav();
    positionSlideNav();
  });
});

function toggleNav() {
  if ($('#site-wrapper').hasClass('show-nav')) {
    // Do things on Nav Close
    $('#site-wrapper').removeClass('show-nav');
  } else {
    // Do things on Nav Open
    $('#site-wrapper').addClass('show-nav');
  }

  //$('#site-wrapper').toggleClass('show-nav');
}

//bootstrap modal dialog
$(document).ready(function () {
    $('a[href^="mailto:"]').click(function (e) {
        var emailMailTo = $(this).attr('href');

        createBootstrapModal($('#hfModalEmail').val(), [{
            classes: '',
            label: 'OK',
            callback: function () {
                location.href = emailMailTo
            }
        }]);

        e.preventDefault();
    });

    $('a[target]:not([target=""])').click(function (e) {
        var link = $(this).attr('href');

        createBootstrapModal($('#hfModalLink').val(), [{
            classes: '',
            label: 'OK',
            callback: function () {
                window.open(link);
            }
        }]);

        e.preventDefault();
    });
});

//bootstrap modal with youtube video
$(document).ready(function () {
    /* Get iframe src attribute value i.e. YouTube video url
    and store it in a variable */
    var url = $("#videoIframe").attr('src');

    /* Remove iframe src attribute on page load to
    prevent autoplay in background */
    $("#videoIframe").attr('src', '');

    /* Assign the initially stored url back to the iframe src
    attribute when modal is displayed */
    $("#mediaModal").on('shown.bs.modal', function () {
        $("#videoIframe").attr('src', url);
    });

    /* Assign empty url value to the iframe src attribute when
    modal hide, which stop the video playing */
    $("#mediaModal").on('hide.bs.modal', function () {
        $("#videoIframe").attr('src', '');
    });
});

/**
 * Adjusting the Navigation sub-menus to be equal height as their parent
 */
 window.onload = function(){
   $(".dropdown-menu").each(function(){
     var maxHeight = 0,
     menuHeight = $(this).outerHeight();
     borderWidth = parseInt($(this).css("border-top-width"));
     $(this).find(".dropdown-submenu").each(function(i){
       var menu = $(this).find(".dropdown-menu");
       //offset the submenu to line up with the menu
       var offsetY = $(this).position().top + borderWidth;
       menu.css({marginTop:-offsetY});
       var h = menu.outerHeight();
       if(h > maxHeight){
         maxHeight = h;
       }
       var par = $(this).parents(".dropdown-menu");
       if(i == par.find(".dropdown-submenu").length-1){
         //last item
         if(maxHeight > menuHeight){
          par.find(".dropdown-submenu .dropdown-menu").height(maxHeight);
          par.height(maxHeight);
         } else {
           par.find(".dropdown-submenu .dropdown-menu").height(menuHeight);
           par.height(menuHeight);
         }
       }
     })
   })
   $(".dropdown-menu").css({opacity:1,display:'none'});


 }


/*
* This function will add a class to show the slideout nav bar and
* inject a small portion of text to the top of the closest slideout
* nav bar, to be used as a 'breadcrumb'
*/
function handleSlideoutNav($this) {

  var pageName   = $this.html(),
      newElement = $("<span class='previous-section'>" +pageName+ "</span>"),
      newElementText = $this.next('.navbar-slideout').find(".previous-section").html();

    // if the text has not yet been added to the top of the next slideout nav, add it
    // otherwise, make sure the text at the top of the next slideout nav matches the 
    // clicked section, and add it if it doesn't match
    if (!newElementText) {
        $this.next('.navbar-slideout').find('.hide-navbar-slideout').append(newElement);
    } else if (newElementText != pageName) {
        $this.next('.navbar-slideout').find(".previous-section").html(pageName);
    }

    $this.next('ul').addClass('show-navbar-slideout');
}


/*
*  an element with this id will toggle the display of the slideout nav
*  when clicked
*/
function positionSlideNav() {
    $('.toggle-slideout-nav').next('ul').addClass('navbar-slideout');
};


$(document).ready(function() {
    var centerSearchIcon = $('.caption .slide-panel .glyphicon-search');

    // toggles the search bar on the homepage
    $(".slide-panel").click(function (e) {
        e.stopPropagation();
        $(".search-panel").slideToggle(400);
        $('#focusedInput').focus();

        if (centerSearchIcon.is(':visible')) {
            centerSearchIcon.hide(); //home only
        } else {
            setTimeout(function() {
                centerSearchIcon.show();
            }, 400); // show the search icon after the animation is done
        }
    });
    //Anchor home tiles on click
    $(".accordion--home ul li a").click(function () {
        $('html, body').animate({
            scrollTop: $(this).offset().top
        }, 600);
    });



    // hides the slideout search bar and displays the 
    // search icon when some page location is clicked
    $("body:not('.slide-panel')").click(function (e) {
        //e.stopPropagation();  we want it to propogate!
        var container = $(".search-panel");

        if (container.is(":visible") // is the container slid out?
            && !container.is(e.target) // was the container not clicked?
            && container.has(e.target).length === 0) { // were no children clicked?

            $(".search-panel").slideToggle(400);
            setTimeout(function() {
                centerSearchIcon.show();
            }, 400);
        }
    });

    // handler for the mobile slideout navigation
    $('.toggle-slideout-nav').click(function(){
        var $this = $(this); // cache a copy of 'this' because of functional scope

        if ($(window).width() < 992) {
            handleSlideoutNav($this);
        }
    });

    // slides the slideout nav back behind the page canvas, to its original place
    $('.hide-navbar-slideout').click(function() {
        $(this).closest('.navbar-slideout').removeClass('show-navbar-slideout');
    });

    // start resize listener
    optimizedResize.init(function() {
        var elementDisplay = $('.navbar-slideout');
        
        // if we've toggled the mobile nav, then resized the window up
        if (elementDisplay.css("display") === "block" && $(window).width() > 992) {
            elementDisplay.removeClass('navbar-slideout');
            $('.show-navbar-slideout').removeClass('show-navbar-slideout');
            $('#site-canvas').css({left: '0px'});
        }
    });
    
    // datepicker logic
    $('.datepicker').datetimepicker({
        language: $('.datepicker > input').data('lang'),
        pick12HourFormat: $('.datepicker > input').data('12hr'),
        pickDate: !($('.datepicker > input').data('nopick') == 'date'),
        pickTime: !($('.datepicker > input').data('nopick') == 'time')
    });
    $('.timepicker tbody tr:first-child i').removeClass('icon-chevron-up').addClass('glyphicon glyphicon-chevron-up');
    $('.timepicker tbody tr:last-child i').removeClass('icon-chevron-down').addClass('glyphicon glyphicon-chevron-down');

    /*
    *  This function builds a URL with the correct search parameters
    *  based on what is supplied to the search box, and then searches
    *  using the supplied text. It is needed because of a flaw in the
    *  Kentico search feature appearing on certain pages
    */
    $('#focusedInput').on('keypress', function (e) {
        if (e.which == 13 && !e.shiftKey) {
            e.preventDefault();

            var searchText = $('#focusedInput').val(),
            protocol = window.location.protocol,
            host = window.location.host,
            path = "search?searchtext=" + searchText.replace(' ', '+'),
            newUrl = protocol + "//" + host + "/" + path;

            document.location.href = newUrl;
        }
    });

    /* Blog featured posts carousel */
    if ($(".blog-page").length) {
        $('.featured-slider-for').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            arrows: false,
            fade: true,
            asNavFor: '.featured-slider-nav'
        });
        $('.featured-slider-nav').slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            asNavFor: '.featured-slider-for',
            dots: false,
            arrows: false,
            centerMode: false,
            focusOnSelect: true
        });
    }
});
