function createBootstrapModal(message, buttons) {
    if ($('.custom-modal'))
        $('.custom-modal').remove();

    $('body').append($('<div class="custom-modal modal fade"><div class="modal-dialog"><div class="modal-content"><div class="modal-header"><button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span class="sr-only">Close</span></button><h4 class="modal-title"></h4></div><div class="modal-body"></div><div class="modal-footer"></div></div></div></div>));'));

    $('.modal-body').html(message);

    for (var x = 0; x < buttons.length; x++) {
        var button = $('<button class="btn ' + buttons[x].classes + '">' + buttons[x].label + '</button>');
        button.data('index', x);
        $('.modal-footer').append(button);
        $(button).click(function () {
            $('.custom-modal').modal('hide');

            if (buttons[$(this).data('index')].callback)
                buttons[$(this).data('index')].callback();
        });
    }

    $('.custom-modal').show();
    $('.custom-modal').modal();

    $('.custom-modal').on('hidden', function () {
        $(this).removeData('model');
    });
}