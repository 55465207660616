// help with query params
function getParameterByName(name) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
        results = regex.exec(location.search);
    return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
}

// custom function to help with month grouping
ko.observableArray.fn.distinct = function (prop) {
    var target = this;
    target.index = {};
    target.index[prop] = ko.observable({});

    ko.computed(function () {
        //rebuild index
        var propIndex = {};

        ko.utils.arrayForEach(target(), function (item) {
            var key = ko.utils.unwrapObservable(item[prop]);
            if (key) {
                propIndex[key] = propIndex[key] || [];
                propIndex[key].push(item);
            }
        });

        target.index[prop](propIndex);
    });

    return target;
};

// insures values have values
var insureValue = function (value, defaultValue) {
    return typeof (value) !== 'undefined' ? value : defaultValue;
};

// array of month names
var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

// object definition
var listingModel = {
    apiPath: '/api/listing',
    call: {
        getDone: function (data) {
            listingModel.items().length = 0;

            $('#pnlCaseStudyPage').html('');
            $('#pnlArticle').html('');
            $('#pnlEventPage').html('');

            //if (listingModel.filter.type === 'Site.EventPage') {
            //    var rows = [], cols = [];
            //    data.items.forEach(function (item, i) {
            //        cols.push(item);
            //        if (cols.length === listingModel.columnCount || i === data.items.length - 1) {
            //            rows.push(cols);
            //            cols = [];
            //        }
            //    });
            //    console.log(rows[0].length);
            //    listingModel.items = ko.observableArray(data.items).distinct('month');
            //} else {
            var cols = [];
            data.items.forEach(function (item, i) {
                cols.push(item);
                if (cols.length === listingModel.columnCount || i === data.items.length - 1) {
                    console.log(cols);
                    listingModel.items.push(cols);
                    cols = [];
                }
            });
            //}

            if (!addThisLoaded) {
                var script = 'https://s7.addthis.com/js/300/addthis_widget.js#async=1&pubid=ra-541aea3768113adb';
                if (window.addthis) {
                    window.addthis = null;
                    window._adr = null;
                    window._atc = null;
                    window._atd = null;
                    window._ate = null;
                    window._atr = null;
                    window._atw = null;
                }
                $.getScript(script, function () { addThisLoaded = true; addthis.init(); });

                if (listingModel.filter.pageTracker.max > 0) {
                    $('.pagination:not(.letters)').empty();
                    for (var i = 1; i < data.tracker.page.count + 1; i++) {
                        $('.pagination:not(.letters)').append('<li class="' + (i == data.tracker.page.current ? 'active' : '') + '"><a href="#">' + i + '</a></li>');
                    }
                }
            } else {
                addthis.button('.addthis_button_compact');

                if (listingModel.filter.pageTracker.max > 0) {
                    $('.pagination:not(.letters)').empty();
                    for (var i = 1; i < data.tracker.page.count + 1; i++) {
                        $('.pagination:not(.letters)').append('<li class="' + (i == data.tracker.page.current ? 'active' : '') + '"><a href="#">' + i + '</a></li>');
                    }
                }
            }

            if ($('#pnlListItems > div').length < 1) {
                var noResult = 'Sorry, no results found.';
                $('#pnlListItems').append('<h3 class="text-center">' + noResult + '</h3>');
            }

            listingModel.filter.itemTracker = data.tracker.item;
            listingModel.filter.pageTracker = data.tracker.page;

            $('.listing-loader').hide();
            $('.pagination').show();
            //localStorage.setItem('filter-' + window.location.href, JSON.stringify(listingModel.filter));
        },
        getFail: function (error) {
            console.log(error.responseText);
            $('.listing-loader').hide();
            $('.pagination').show();
        },
        get: function () {
            $('.pagination').hide();
            $('.listing-loader').show();
            $.get(listingModel.apiPath, { 'filter': listingModel.filter })
                .done(this.getDone)
                .fail(this.getFail);
        }
    },
    columnCount: 0,
    filter: {
        bio: 0,
        industry: null,
        itemTracker: null,
        keyword: null,
        pageTracker: null,
        practiceArea: null,
        type: null,
        year: null
    },
    load: {
        last: function () {
            var page = listingModel.filter.pageTracker;
            var index = page.index - 1;
            listingModel.load.page(index < 0 ? 0 : index, page.size);
        },
        next: function () {
            var page = listingModel.filter.pageTracker;
            var index = page.index + 1;
            listingModel.load.page(index > page.last ? page.last : index, page.size);
        },
        page: function (pageIndex, pageSize) {
            listingModel.filter.pageTracker.index = insureValue(pageIndex, 0);
            listingModel.filter.pageTracker.size = insureValue(pageSize, 1);
            listingModel.call.get();
        }
    },
    items: ko.observableArray([]),
    tracker: function tracker() {
        this.count = 0;
        this.current = 0;
        this.max = 0;
    }
};



var addThisLoaded = false;

// Trackers
var instantiateTrackers = function () {
    listingModel.filter.pageTracker = new listingModel.tracker();
    listingModel.filter.itemTracker = new listingModel.tracker();
}
var setupTrackers = function () {
    var filter = listingModel.filter;
    filter.pageTracker.current = filter.pageTracker.current <= 0 ? 1 : filter.pageTracker.current;
    filter.itemTracker.max = parseInt($('#pnlListItems').data('item_max'));
    filter.pageTracker.max = parseInt($('#pnlListItems').data('page_max'));
}

// Ready
$(function () {
    ko.applyBindings(listingModel.items);
    instantiateTrackers();
    if ($('#pnlListItems').length > 0) {
        listingModel.columnCount = parseInt($('#pnlListItems').data('column_count'));

        //var storedFilter = localStorage.getItem('filter-' + window.location.href);
        //if (storedFilter !== null && typeof (storedFilter) !== 'undefined' && storedFilter !== '') {
        //    listingModel.filter = JSON.parse(storedFilter);
        //}

        var query = window.location.href.indexOf('?') > 0 ? window.location.href.split("?")[1] : '';
        if (query !== '') {
            if (query.indexOf('l') === 0) { // letter
                clearFilter();
                listingModel.filter.keyword = getParameterByName('l');
            } else if (query.indexOf('q') === 0) { // text
                clearFilter();
                listingModel.filter.keyword = getParameterByName('q');
            } else if (query.indexOf('t') === 0) { // type
                clearFilter();
                var t = getParameterByName('t').toLowerCase();
                $('#ddlType option').each(function () {
                    var v = $(this).val();
                    if (v !== '' && v[5].toLowerCase() === t) {
                        listingModel.filter.type = v;
                    }
                });
            }
        } else {
            listingModel.filter.keyword = "";
        }

        if ($('.filters-form').length > 0) {
            $('#txtKeyword').val(listingModel.filter.keyword);
            $('#ddlIndustry').val(listingModel.filter.industry);
            $('#ddlService').val(listingModel.filter.practiceArea);
            if ($('#ddlType').length > 0) {
                if (listingModel.filter.type !== null && listingModel.filter.type.indexOf('|') < 0) {
                    $('#ddlType').val(listingModel.filter.type);
                }
            }
            $('#ddlYear').val(listingModel.filter.year);
        }

        if (listingModel.filter.type === null) {
            listingModel.filter.type = $('#pnlListItems').data('type');
        }

        setupTrackers();
        listingModel.call.get();

        $(document).on('click', '.pagination > li > a', function () {
            listingModel.filter.pageTracker.current = parseInt($(this).text());
            listingModel.call.get();
        });
    }

    // tabs
    $('.nav-tabs').on('click', 'li:not(:first-child) > a', function () {
        callViaTab($(this));
    });

    // accordion
    $('.panel-group').on('click', 'div:not(:first-child) .panel-title a', function () {
        var href = $(this).attr('href');
        href = href.substring(href.indexOf('-')).replace('-', '#');
        callViaTab($('.nav-tabs a[href="' + href + '"]'));
    });

    $('.filters-form #btnFilterClear').click(function () {
        clearFilterInputs();
    });

    $('.filters-form #btnFilter').click(function () {
        $('#pnlListItems').html('');

        listingModel.filter.keyword = $('#txtKeyword').val();
        listingModel.filter.industry = $('#ddlIndustry').val();
        listingModel.filter.practiceArea = $('#ddlService').val();
        if ($('#ddlType').length > 0) {
            if ($('#ddlType').val() !== '') {
                listingModel.filter.type = $('#ddlType').val();
            }
            else {
                $('#ddlType option').map(function () {
                    var v = $(this).val();
                    if (v !== '') {
                        return v;
                    }
                }).get().join('|');
            }
        }
        listingModel.filter.year = $('#ddlYear').val();

        listingModel.call.get();
    });

    $('.alpha-txt').on('keypress', function (e) {
        if (e.which == 13 && !e.shiftKey) {
            e.preventDefault();
            var text = $(this).val().trim();
            if (text.length > 2) {
                window.location = '/professionals?q=' + text;
            }
        }
    });

    $('.alpha-btn').on('click', function (e) {
        e.preventDefault();
        var text = $(this).closest('div.add-on').find('.alpha-txt').val().trim();
        if (text.length > 2) {
            window.location = '/professionals?q=' + text;
        }
    });
});

var callViaTab = function ($tab) {
    //if ($('#pnl' + listingModel.filter.type.substring(5)).is(':empty')) {
    listingModel.columnCount = 2;
    listingModel.filter.type = $tab.data('type');
    listingModel.filter.bio = $('#pnlMain').data('bio');
    listingModel.filter.practiceArea = $('#pnlMain').data('practicearea');
    $('#pnl' + listingModel.filter.type.substring(5).replace(/\./g, '_').replace(/\|/g,'_')).html('');
    console.log(listingModel.columnCount);
    console.log(listingModel.filter.type);
    console.log(listingModel.filter.bio);
    console.log(listingModel.filter.practiceArea);
    console.log('#pnl' + listingModel.filter.type.substring(5));
    listingModel.call.get();
}

var clearFilter = function () {
    listingModel.filter = {
        bio: 0,
        industry: null,
        itemTracker: null,
        keyword: null,
        pageTracker: null,
        practiceArea: null,
        type: null,
        year: null
    };
    instantiateTrackers();
}

var clearFilterInputs = function () {
    $('#txtKeyword').val('');
    $('#ddlIndustry').val('');
    $('#ddlService').val('');
    $('#ddlType').val('');
    $('#ddlYear').val('');
    listingModel.filter.type = $('#ddlType option').map(function () {
        var v = $(this).val();
        if (v !== '') {
            return v;
        }
    }).get().join('|');
}

var getTemplate = function (item) {
    return item[0].template;
}